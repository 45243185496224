.catHolder h1 {
    font-size: 1.3em;
    border-bottom: 1px solid;
    border-color: #7DF9FF;
}

.catHolder {
    margin: 0 auto;
    margin-top: 5vh;
    text-align: justify;
}

@media only screen and (max-width: 600px) {
    .catHolder {
        width: 80vw;
    }

    .catHolder h1 {
        font-size: 1.3em;
    }

    .catHolder h2 {
        font-size: 1.1em;
    }

    .catBox1 {
        font-size: 1em;
    }

    .catBox2 {
        width: 80vw;
        margin: auto;
        display: block;
    }
}

@media only screen and (min-width: 600px) {
    .catHolder {
        width: 70vw;
    }

    .catHolder h1 {
        font-size: 1.3em;
    }

    .catHolder h2 {
        font-size: 1.1em;
    }

    .catBox1 {
        font-size: 1.1em;
    }

    .catBox2 {
        width: 60vw;
        margin: auto;
        display: block;
    }
}

@media only screen and (min-width: 992px) {
    .catHolder {
        width: 50vw;
    }

    .catHolder h2 {
        font-size: 1.3em;
    }

    .catHolder h1 {
        font-size: 1.8em;
    }

    .catBox1 {
        font-size: 1.2em;
    }

    .catBox2 {
        width: 30vw;
        margin: auto;
        display: block;
    }
}