.contactBox {
    border-top: 1px solid;
    border-color: #7DF9FF;
}

.contactBox p {
    color: #EFEFEF;
}

@media only screen and (max-width: 600px) {
    .contactBox {
        width: 80vw;
        margin: 0 auto;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }

    .contactBox p {
        font-size: 1em;
    }

    .contactBox h2 {
        font-size: 1.3em;
    }

    #buttonBox {
        width:100%;
        display: flex;
        justify-content: center;
    }

    .contactBox a {
        display: block;
        width: 40%;
        height: 5%;
        background:#2e4584;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        line-height: 25px;
    }

    #contactButton {
        background-color: #4CAF50; /* Green */
        border-radius: 12px;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 1em;
    }
}

@media only screen and (min-width: 600px) {
    .contactBox {
        width: 80vw;
        margin: 0 auto;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }

    .contactBox p {
        font-size: 1em;
    }

    .contactBox h2 {
        font-size: 1.3em;
    }

    #buttonBox {
        width:100%;
        display: flex;
        justify-content: center;
    }

    .contactBox a {
        display: block;
        width: 40%;
        height: 5%;
        background:#2e4584;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        line-height: 25px;
    }

    #contactButton {
        background-color: #4CAF50; /* Green */
        border-radius: 12px;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 1em;
    }
}

@media only screen and (min-width: 992px) {
    .contactBox {
        width: 60vw;
        margin: 0 auto;
        margin-top: 20vh;
        margin-bottom: 20vh;
    }

    .contactBox p {
        font-size: 1.2em;
    }

    .contactBox h2 {
        font-size: 1.8em;
    }

    #buttonBox {
        width:100%;
        display: flex;
        justify-content: center;
    }

    .contactBox a {
        display: block;
        width: 40%;
        height: 5%;
        background:#2e4584;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        line-height: 25px;
    }

    #contactButton {
        background-color: #4CAF50; /* Green */
        border-radius: 12px;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 1.2em;
    }
}