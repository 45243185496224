.container {
    color: #C0C0C0;
}

.container h1 {
    text-align: center;
    color: #EFEFEF;
}

#Github {
    text-align: center;
    margin-bottom: 5vh;
}

.box2 {
    color: #EFEFEF;
    border-color: #7DF9FF;
}

.left {
    float: left;
}

.right {
    float: right;
}

.modelStats {
    border-top: 1px solid;
    border-color: #7DF9FF;
}

@media only screen and (max-width: 600px) {
    .container {
        padding-top: 5vh;
        width: 80vw;
        margin: 0 auto;
    }

    .container h1 {
        font-size: 1.2em;
    }

    #Github {
        font-size: 1.2em;
    }

    .box1 {
        width: 100%;
        text-align: justify;
        border-color: #7DF9FF;
    }

    .container p {
        font-size: 1em;
    }

    .exampleBox {
        margin-bottom: 5vh;
    }

    .exampleImage {
        display: block;
        width: 80vw;
    }

    .titleHolder {
        text-align: center;
        margin: auto;
        width: 100%;
        font-size: 0.8em;
    }

    .modelImg {
        width: 70vw;
    }

    .box2 {
        border-top: 1px solid;
        border-color: #7DF9FF;
    }
}

@media only screen and (min-width: 600px) {
    .container {
        padding-top: 5vh;
        width: 80vw;
        margin: 0 auto;
    }

    .container h1 {
        font-size: 1.2em;
    }

    #Github {
        font-size: 1.2em;
    }

    .box1 {
        width: 100%;
        text-align: justify;
        border-color: #7DF9FF;
    }

    .container p {
        font-size: 1em;
    }

    .exampleBox {
        margin-bottom: 5vh;
    }

    .exampleImage {
        display: block;
        width: 80vw;
    }

    .titleHolder {
        text-align: center;
        margin: auto;
        width: 100%;
        font-size: 0.8em;
    }

    .modelImg {
        width: 70vw;
    }

    .box2 {
        border-top: 1px solid;
        border-color: #7DF9FF;
    }
}

@media only screen and (min-width: 992px) {
    .container {
        padding-top: 5vh;
        width: 50vw;
        margin: 0 auto;
    }

    .container h1 {
        font-size: 1.8em;
    }

    #Github {
        font-size: 1.6em;
    }

    .box1 {
        width: 100%;
        text-align: justify;
        border-color: #7DF9FF;
    }

    .container p {
        font-size: 1.2em;
    }

    .exampleBox {
        margin-bottom: 5vh;
    }

    .exampleImage {
        display: block;
        width: 100%;
    }

    .titleHolder {
        text-align: center;
        margin: auto;
        width: 100%;
        font-size: 1em;
    }

    .modelImg {
        width: 30vw;
        margin: auto;
        display: block;
    }

    .box2 {
        border-top: 1px solid;
        border-color: #7DF9FF;
    }
}