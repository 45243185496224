a:link, a:visited, a:active {
    text-decoration: none;
    color: #7DF9FF;
}

a:hover {
    text-decoration: underline;
}

.language-button {
    border: none;
    color: #C0C0C0; /* White text */
    padding: 5px 10px; /* Some padding */
    text-align: center; /* Center the text */
    font-size: 16px; /* Increase font size */
    margin: 4px 2px; /* Add some margin */
    cursor: pointer; /* Add a pointer cursor on hover */
    background: none; /* Dark background */
}

.language-button:hover {
    text-decoration: underline;
    text-decoration-color: #7DF9FF;
}

@media only screen and (max-width: 600px) {
    #header_bar ul {
        list-style-type: none;
        display: flex;
        margin: 0;
        padding-top: 10px;
        font-size: 1em;
    }

    #header_bar li{
        text-decoration: none;
        padding-left: 10px;
    }
}

@media only screen and (min-width: 600px) {
    #header_bar ul {
        float: right;
        list-style-type: none;
        display: flex;
        width: auto;
        margin: 0;
        font-size: 1em;
        align-items: center;
    }

    #header_bar li{
        padding: 20px;
        text-decoration: none;
    }

    #header_bar {
        overflow: auto;
        position: relative;
    }

    #name {
        position: absolute;
        left: 0;
    }
}

@media only screen and (min-width: 992px) {
    #header_bar ul {
        float: right;
        list-style-type: none;
        display: flex;
        align-items: center;
        width: auto;
        margin: 0;
        margin-right: 50px;
        font-size: 1.2em;
    }

    #header_bar li{
        padding: 20px;
        text-decoration: none;
    }

    #header_bar {
        overflow: auto;
        position: relative;
    }

    #name {
        position: absolute;
        left: 0;
    }
}