.title {
    color: #EFEFEF;
}

.description {
    color: #C0C0C0;
    border-top: solid 1px;
    border-color: #7DF9FF;
}

@media only screen and (max-width: 600px) {

    #home {
        margin: 0 auto;
        width: 80vw;
    }

    .title {
        font-size: 1.3em;
    }

    .description {
        font-size: 1em;
        margin-bottom: 10vh;
        width: auto;
        text-align: justify;
    }
}

@media only screen and (min-width: 600px) {
    #home {
        margin: 0 auto;
        width: 80vw;
    }

    .title {
        font-size: 2em;
    }

    .description {
        font-size: 1em;
        margin-bottom: 10vh;
        width: auto;
        text-align: justify;
    }
}

@media only screen and (min-width: 992px) {
    #home {
        margin-left: 20vw;
        margin-top: 10vw;
        width: 40vw;
    }

    .title {
        font-size: 4em;
    }

    .description {
        font-size: 1.3em;
        margin-bottom: 40vh;
        width: auto;
        text-align: justify;
    }
}