footer {
    height: auto;
}

.footerContent {
    background-color: #1D2B53;
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
}

.footerContent li {
    font-size: 1em;
    display: inline;
}

.footerContent ul{
    text-decoration: none;
}