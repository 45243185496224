.aboutTitle {
    display: flex;
    align-items: center;
}

.aboutTitle:after {
    content: '';
    flex: 1;
    margin-left: 1rem;
    height: 1px;
    background-color: #7DF9FF;
}

.profileImage {
    border-radius: 5%;
    border: 1pt solid;
    border-color: #C0C0C0;
}

.about {
    display: flex;
    margin-bottom: 20vh;
}

@media only screen and (max-width: 600px) {

    .informationBox {
        width: 80vw;
        margin: 0 auto;
    }

    .profileImageBox {
        margin: 0 auto;
    }

    .profileImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50vw;
    }

    .aboutTitle {
        font-size: 1.3em;
    }

    .descriptionAbout {
        font-size: 1em;
        color: #C0C0C0;
        text-align: justify;
        padding-right: 5%;
    }

    .about {
        flex-direction: column;
    }
}

@media only screen and (min-width: 600px) {
    .informationBox {
        width: 80vw;
        margin: 0 auto;
    }

    .profileImageBox {
        margin: 0 auto;
    }

    .profileImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 30vw;
    }

    .aboutTitle {
        font-size: 1.3em;
    }

    .descriptionAbout {
        font-size: 1em;
        color: #C0C0C0;
        text-align: justify;
        padding-right: 5%;
    }

    .about {
        flex-direction: column;
    }
}

@media only screen and (min-width: 992px) {
    .informationBox {
        width: 50vw;
        margin: 0 auto;
    }

    .profileImageBox {
        margin: 0 auto;
    }

    .profileImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 15vw;
    }

    .aboutTitle {
        font-size: 1.8em;
    }

    .descriptionAbout {
        font-size: 1.2em;
        color: #C0C0C0;
        text-align: justify;
        padding-right: 5%;
    }

    .about {
        flex-direction: row;
        align-items: center;
    }
}