.minecraftClone {
    margin: 0 auto;
    margin-top: 5vh;
    display: flex;
    text-align: justify;
    margin-bottom: 40vh;
}

@media only screen and (max-width: 600px) {
    .minecraftClone {
        flex-direction: column;
    }

    .leftSide {
        width: 80vw;
        margin: 0 auto;
    }

    .box2 {
        width: 50%;
        margin: 0 auto;
        display: block;
    }
}

@media only screen and (min-width: 600px) {
    .minecraftClone {
        flex-direction: column;
    }

    .leftSide {
        width: 80vw;
        margin: 0 auto;
    }

    .box2 {
        width: 50%;
        margin: 0 auto;
        display: block;
    }
}

@media only screen and (min-width: 992px) {
    .minecraftClone {
        flex-direction: column;
    }

    .leftSide {
        width: 60vw;
        margin: 0 auto;
    }

    .box2 {
        width: 50%;
        margin: 0 auto;
        display: block;
    }
}